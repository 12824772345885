<template>
  <div class="room-detail">
    <app-detail :opt="opt" ref="detail"></app-detail>
  </div>
</template>
<script>
let validBuilding = (rule, value, callback) => {
  let reg = /^[a-zA-Z0-9]{1,14}$/;
  if (!reg.test(value)) {
    callback(new Error("楼栋为字母或数字最多15位字符"));
  } else {
    callback();
  }
};
let validRoomNo = (rule, value, callback) => {
  //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
  let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
  if (!reg.test(value)) {
    callback(
      new Error(
        "房间号仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符"
      )
    );
  } else {
    callback();
  }
};
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "房间详情",
        electricityFeesFlag: false,
        form: [
          {
            label: "房源",
            key: "apartmentId",
            isCol50: true,
            isWidth67: true,
            type: "select",
            opt: {
              list: [],
              change(data) {
                // 赋值楼栋数
                let list = _this.opt.form[0].opt.list;
                _this.opt.form[3].opt.list = [];
                list.forEach((item) => {
                  if (item.value == data) {
                    if (item.buildingList && item.buildingList.length > 0) {
                      item.buildingList.forEach((it) => {
                        _this.opt.form[3].opt.list.push({
                          label: it.buildName,
                          value: Number(it.buildCode),
                        });
                      });
                    } else {
                      for (var i = 0; i < item.buildings; i++) {
                        _this.opt.form[3].opt.list.push({
                          label: i + 1 + "栋",
                          value: i + 1,
                        });
                      }
                    }
                    _this.buildingsList = _this.opt.form[3].opt.list;
                  }
                });
                if (!data) {
                  data = location.search.split("=")[1];
                  _this.getTypeIdList(data);
                } else {
                  _this.getTypeIdList(data);
                }
              },
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "租赁模式",
            key: "rentalStyle",
            isCol50: true,
            isWidth67: true,
            type: "select",
            opt: {
              list: [
                {
                  label: "长租",
                  value: "0",
                },
                {
                  label: "短租",
                  value: "1",
                },
                {
                  label: "长短租",
                  value: "2",
                },
                {
                  label: "钟点房",
                  value: "3",
                },
              ],
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "房型",
            key: "roomType",
            type: "select",
            isCol50: true,
            isWidth67: true,
            disabled: true,
            opt: {
              list: [],
              parent: "apartmentId",
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "楼栋名称",
            key: "buildingNo",
            type: "select",
            disabled: true,
            isCol50: true,
            isWidth67: true,
            opt: {
              list: [],
              parent: "apartmentId",
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "楼层",
            key: "floor",
            type: "number",
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "房号",
            key: "roomNo",
            maxlength: "24",
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
                validator: validRoomNo,
                trigger: "blur",
              },
            ],
          },
          {
            label: "租赁合同类型",
            key: "contractType",
            type: "select",
            value: 1,
            opt: {
              list: [
                {
                  label: "线下纸质合同",
                  value: 1,
                },
                {
                  label: "CA电子合同",
                  value: 2,
                },
              ],
            },
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "每月交租日期",
            key: "payDate",
            isCol50: true,
            isWidth67: true,
            type: "select",
            opt: {
              list: [
                {
                  value: 4,
                  label: "每月4号",
                },
                {
                  value: 5,
                  label: "每月5号",
                },
                {
                  value: 6,
                  label: "每月6号",
                },
                {
                  value: 7,
                  label: "每月7号",
                },
                {
                  value: 8,
                  label: "每月8号",
                },
                {
                  value: 9,
                  label: "每月9号",
                },
                {
                  value: 10,
                  label: "每月10号",
                },
              ],
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "允许逾期天数",
            key: "expiredDay",
            isCol50: true,
            isWidth67: true,
            type: "select",
            value: 3,
            opt: {
              list: [
                {
                  value: 0,
                  label: "当天",
                },
                {
                  value: 1,
                  label: "1天",
                },
                {
                  value: 2,
                  label: "2天",
                },
                {
                  value: 3,
                  label: "3天",
                },
                {
                  value: 4,
                  label: "4天",
                },
                {
                  value: 5,
                  label: "5天",
                },
                {
                  value: 6,
                  label: "6天",
                },
                {
                  value: 7,
                  label: "7天",
                },
                {
                  value: 8,
                  label: "8天",
                },
                {
                  value: 9,
                  label: "9天",
                },
                {
                  value: 10,
                  label: "10天",
                },
                {
                  value: 11,
                  label: "11天",
                },
                {
                  value: 12,
                  label: "12天",
                },
                {
                  value: 13,
                  label: "13天",
                },
                {
                  value: 14,
                  label: "14天",
                },
                {
                  value: 15,
                  label: "15天",
                },
              ],
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "逾期当天截止至",
            key: "expiredTime",
            isCol50: true,
            isWidth67: true,
            type: "select",
            value: 12,
            opt: {
              list: [
                {
                  value: 0,
                  label: "00:00",
                },
                {
                  value: 1,
                  label: "01:00",
                },
                {
                  value: 2,
                  label: "02:00",
                },
                {
                  value: 3,
                  label: "03:00",
                },
                {
                  value: 4,
                  label: "04:00",
                },
                {
                  value: 5,
                  label: "05:00",
                },
                {
                  value: 6,
                  label: "06:00",
                },
                {
                  value: 7,
                  label: "07:00",
                },
                {
                  value: 8,
                  label: "08:00",
                },
                {
                  value: 9,
                  label: "09:00",
                },
                {
                  value: 10,
                  label: "10:00",
                },
                {
                  value: 11,
                  label: "11:00",
                },
                {
                  value: 12,
                  label: "12:00",
                },
                {
                  value: 13,
                  label: "13:00",
                },
                {
                  value: 14,
                  label: "14:00",
                },
                {
                  value: 15,
                  label: "15:00",
                },
                {
                  value: 16,
                  label: "16:00",
                },
                {
                  value: 17,
                  label: "17:00",
                },
                {
                  value: 18,
                  label: "18:00",
                },
                {
                  value: 19,
                  label: "19:00",
                },
                {
                  value: 20,
                  label: "20:00",
                },
                {
                  value: 21,
                  label: "21:00",
                },
                {
                  value: 22,
                  label: "22:00",
                },
                {
                  value: 23,
                  label: "23:00",
                },
                {
                  value: 24,
                  label: "24:00",
                },
              ],
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "房价(元/月)",
            key: "rentalFee",
            type: "number",
            isCol50: true,
            isWidth67: true,
            opt: {
              keyupNative: true,
            },
            min: _this.UPLOAD_URL != _this.allUrl.pro.UPLOAD_URL ? 0.1 : 1,
            max: 1000000,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "押金(元)",
            key: "deposit",
            type: "number",
            opt: {
              keyupNative: true,
            },
            min: 0,
            max: 1000000,
            isCol50: true,
            isWidth67: true,
          },
          {
            label: "物业费(元/月)",
            key: "propertyFee",
            min: 0,
            max: 1000000,
            type: "number",
            opt: {
              keyupNative: true,
            },
            isCol50: true,
            isWidth67: true,
          },
          {
            subLabel: "",
            type: "TitleName",
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "电费",
            key: "electricityFees",
            value: "4",
            type: "select",
            opt: {
              list: [
                {
                  label: "自理",
                  value: "4",
                },
                {
                  label: "固定单价",
                  value: "2",
                },
                // {
                //   label: "固定金额",
                //   value: "3"
                // },
                // {
                //   label: "阶梯收费",
                //   value: "1"
                // },
              ],
            },
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            subLabel: "",
            type: "TitleName",
            filters: [
              {
                key: "electricityFees",
                value: "4",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "价格（元/度）",
            key: "electricityFeesPrice",
            filters: [
              {
                key: "electricityFees",
                value: "2",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
            opt: {
              keyupNative: true,
            },
          },
          {
            label: "冷水",
            key: "coldWater",
            value: "4",
            type: "select",
            //1：阶梯收费 2：固定价格   3：固定金额  4：自理
            opt: {
              list: [
                {
                  label: "自理",
                  value: "4",
                },
                {
                  label: "固定单价",
                  value: "2",
                },
                {
                  label: "固定金额",
                  value: "3",
                },
                // {
                //   label: "阶梯收费",
                //   value: "1"
                // },
              ],
            },
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            subLabel: "",
            type: "TitleName",
            filters: [
              {
                key: "coldWater",
                value: "4",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "价格（元/吨）",
            key: "coldWaterPrice",
            filters: [
              {
                key: "coldWater",
                value: "2",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
            opt: {
              keyupNative: true,
            },
          },
          {
            label: "价格（元/月）",
            key: "coldWaterPrice2",
            filters: [
              {
                key: "coldWater",
                value: "3",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
            opt: {
              keyupNative: true,
            },
          },
          {
            label: "热水",
            key: "hotWater",
            value: "4",
            type: "select",
            opt: {
              list: [
                {
                  label: "自理",
                  value: "4",
                },
                {
                  label: "固定单价",
                  value: "2",
                },
                {
                  label: "固定金额",
                  value: "3",
                },
                // {
                //   label: "阶梯收费",
                //   value: "1"
                // },
              ],
            },
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            subLabel: "",
            type: "TitleName",
            filters: [
              {
                key: "hotWater",
                value: "4",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "价格（元/吨）",
            key: "hotWaterPrice",
            filters: [
              {
                key: "hotWater",
                value: "2",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
            opt: {
              keyupNative: true,
            },
          },
          {
            label: "价格（元/月）",
            key: "hotWaterPrice2",
            filters: [
              {
                key: "hotWater",
                value: "3",
              },
            ],
            isCol50: true,
            isWidth67: true,
            rules: [
              {
                required: true,
              },
            ],
            opt: {
              keyupNative: true,
            },
          },
          {
            label: "房间图片",
            key: "roomPicList",
            type: "img-list",
            only: "show",
          },
          {
            label: "房间图片",
            key: "roomPicList",
            type: "upload-img-form",
            only: ["add", "edit"],
            opt: {
              url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
              urlKey: "picPath",
              limit: 6,
              upload(data) {
                console.log(data);
                return true;
              },
              remove(file, fileList) {
                console.log(file, fileList);
                return true;
              },
            },
          },
          {
            label: "房间简介",
            key: "description",
            type: "textarea",
          },
          {
            label: "是否发布到房源页",
            key: "isShow",
            type: "select",
            isCol50: true,
            isWidth67: true,
            value: "1",
            opt: {
              list: [
                {
                  label: "是",
                  value: "0",
                },
                {
                  label: "否",
                  value: "1",
                },
              ],
            },
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
        buttons: [
          {
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              form.rentalDiscountFlag = "0"; //注释是否打折，默认否
              form.shortDiscountFlag = "0"; //注释是否打折，默认否
              if (
                form.rentalDiscountFlag == "1" &&
                (form.rentalStyle == "0" || form.rentalStyle == "2")
              ) {
                if (form.rentalFee <= form.rentalDiscountFee) {
                  return _this.$message({
                    showClose: true,
                    message: "长租的折后价必须小于原价",
                    type: "warning",
                  });
                }
              }
              if (
                form.shortDiscountFlag == "1" &&
                (form.rentalStyle == "1" || form.rentalStyle == "2")
              ) {
                if (form.shortRentFee <= form.shortDiscountFee) {
                  return _this.$message({
                    showClose: true,
                    message: "短租租的折后价必须小于原价",
                    type: "warning",
                  });
                }
              }
              let dto = {
                roomNo: form.roomNo,
                landlordId: _this.cache.getLS("userInfo")["userId"], //房东id
                apartmentId: form.apartmentId,
                rentalStyle: form.rentalStyle,
                payDate: form.payDate,
                expiredDay: form.expiredDay,
                expiredTime: form.expiredTime,
                buildingNo: form.buildingNo,
                // unitNo: form.unitNo,
                contractType: form.contractType,
                roomType: form.roomType,
                deposit: form.deposit,
                floor: form.floor.toString(),
                rentalDiscountFlag: form.rentalDiscountFlag,
                shortDiscountFlag: form.shortDiscountFlag,
                description: form.description,
                isShow: Number(form.isShow),
              };
              let chargingModelData = _this.chargingModel;
              console.log(JSON.stringify(chargingModelData));
              // let chargingModelData=_this.cache.getLS("chargingModel");
              if (form.electricityFeesPrice) {
                form.electricityFeesPrice = parseFloat(
                  form.electricityFeesPrice
                ).toFixed(2);
              }
              if (form.coldWaterPrice) {
                form.coldWaterPrice = parseFloat(form.coldWaterPrice).toFixed(
                  2
                );
              }
              if (form.coldWaterPrice2) {
                form.coldWaterPrice2 = parseFloat(form.coldWaterPrice2).toFixed(
                  2
                );
              }
              if (form.hotWaterPrice) {
                form.hotWaterPrice = parseFloat(form.hotWaterPrice).toFixed(2);
              }
              if (form.hotWaterPrice2) {
                form.hotWaterPrice2 = parseFloat(form.hotWaterPrice2).toFixed(
                  2
                );
              }
              if (
                JSON.stringify(chargingModelData) != "{}" &&
                chargingModelData &&
                form.id
              ) {
                dto.chargingModel = {
                  1: {
                    id: form.electricityFeesId ? form.electricityFeesId : "",
                    roomId: form.id ? form.id : "",
                    model: form.electricityFees,
                    unitPrice: form.electricityFeesPrice,
                    waterElectricId:
                      chargingModelData &&
                      chargingModelData["1"] &&
                      chargingModelData["1"].waterElectricId
                        ? chargingModelData["1"].waterElectricId
                        : "",
                  },
                  2: {
                    id: form.coldWaterId ? form.coldWaterId : "",
                    roomId: form.id ? form.id : "",
                    model: form.coldWater,
                    unitPrice:
                      form.coldWater == 2
                        ? form.coldWaterPrice
                        : form.coldWaterPrice2,
                    waterElectricId:
                      chargingModelData &&
                      chargingModelData["2"] &&
                      chargingModelData["2"].waterElectricId
                        ? chargingModelData["2"].waterElectricId
                        : "",
                  },
                  3: {
                    id: form.hotWaterId ? form.hotWaterId : "",
                    roomId: form.id ? form.id : "",
                    model: form.hotWater,
                    unitPrice:
                      form.hotWater == 2
                        ? form.hotWaterPrice
                        : form.hotWaterPrice2,
                    waterElectricId:
                      chargingModelData &&
                      chargingModelData["3"] &&
                      chargingModelData["3"].waterElectricId
                        ? chargingModelData["3"].waterElectricId
                        : "",
                  },
                };
              } else {
                dto.chargingModel = {
                  1: {
                    id: form.electricityFeesId ? form.electricityFeesId : "",
                    roomId: form.id ? form.id : "",
                    model: form.electricityFees,
                    unitPrice: form.electricityFeesPrice,
                    waterElectricId: "",
                  },
                  2: {
                    id: form.coldWaterId ? form.coldWaterId : "",
                    roomId: form.id ? form.id : "",
                    model: form.coldWater,
                    unitPrice:
                      form.coldWater == 2
                        ? form.coldWaterPrice
                        : form.coldWaterPrice2,
                    waterElectricId: "",
                  },
                  3: {
                    id: form.hotWaterId ? form.hotWaterId : "",
                    roomId: form.id ? form.id : "",
                    model: form.hotWater,
                    unitPrice:
                      form.hotWater == 2
                        ? form.hotWaterPrice
                        : form.hotWaterPrice2,
                    waterElectricId: "",
                  },
                };
              }

              if (form.rentalStyle == 0 || form.rentalStyle == 2) {
                dto.propertyFee = form.propertyFee;
              } else {
                dto.propertyFee = "";
              }

              if (form.rentalStyle == 1 || form.rentalStyle == 2) {
                dto.shortRentFee = form.shortRentFee;
                dto.shortDeposit = form.shortDeposit;
              }

              if (form.rentalDiscountFlag && form.rentalDiscountFlag != "0") {
                dto.rentalDiscountFee = form.rentalDiscountFee;
                dto.rentalFee = form.rentalFee;
                if (form.rentalDiscountType == "1") {
                  dto.rentalDiscountStartDate = moment(
                    form.rentalDiscountTimeRange[0]
                  ).format("YYYY-MM-DD HH:mm:ss");
                  dto.rentalDiscountEndDate = moment(
                    form.rentalDiscountTimeRange[1]
                  ).format("YYYY-MM-DD HH:mm:ss");
                  dto.rentalDiscountFlag = "2";
                }
              } else {
                dto.rentalFee = form.rentalFee;
              }

              if (form.shortDiscountFlag && form.shortDiscountFlag != "0") {
                dto.shortDiscountFee = form.shortDiscountFee;
                dto.shortRentFee = form.shortRentFee;
                switch (form.shortDiscountType) {
                  case "1":
                    let _duration = [];
                    for (
                      let i = 0;
                      i < form.shortDiscountDateRangeList.length;
                      i++
                    ) {
                      let item = form.shortDiscountDateRangeList[i];
                      if (item.length && item.length == 2) {
                        _duration.push([
                          _this.format(item[0], 1),
                          _this.format(item[1], 1),
                        ]);
                      }
                    }
                    dto.shortDiscountDetail = JSON.stringify({
                      duration: _duration,
                    });
                    break;
                  case "2":
                    let _weekly = [];
                    for (
                      let i = 0;
                      i < form.shortDiscountWeeklyRangeList.length;
                      i++
                    ) {
                      let item = form.shortDiscountWeeklyRangeList[i];
                      _weekly.push(Number(item) + 1);
                    }
                    dto.shortDiscountDetail = JSON.stringify({
                      weekly: _weekly,
                    });
                    break;
                  case "3":
                    dto.shortDiscountDetail = JSON.stringify({
                      monthly: form.shortDiscountMonthlyRangeList,
                    });
                    break;
                  default:
                    break;
                }
                dto.shortDiscountFlag = ["1", "4", "6", "5"][
                  form.shortDiscountType
                ];
              } else {
                dto.shortRentFee = form.shortRentFee;
              }

              if (form.roomPicList.length > 0) {
                let imgList = [];
                for (let i = 0; i < form.roomPicList.length; i++) {
                  imgList.push(form.roomPicList[i].url);
                }
                dto.roomPicList = imgList;
              } else {
                dto.roomPicList = [];
              }
              _this.apartmentList.forEach((item) => {
                if (form.apartmentId == item.value) {
                  dto.apartmentName = item.label;
                }
              });
              _this.buildingsList.forEach((item) => {
                if (form.buildingNo == item.value) {
                  dto.buildName = item.label;
                }
              });
              dto.roomInfo = dto.apartmentName + dto.buildName + dto.roomNo;
              let url = "landlord-service/room/createRoom";
              let message = "添加房间成功";
              if (form.id) {
                url = "landlord-service/room/updateRoom";
                dto.id = form.id;
                dto.isWebOrMiniApp = true;
                message = "编辑房间成功";
              }
              console.log(form, dto);
              _this.post(url, dto).then(function () {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success",
                });
                _this.$back();
              });
            },
          },
        ],
        get(opt) {
          let dto = {
            id: opt.id,
          };
          _this.post("landlord-service/room/queryRoom", dto).then((res) => {
            var buildingNoVal = Number(res.buildingNo);
            if (!isNaN(buildingNoVal)) {
              res.buildingNo = buildingNoVal;
            } else {
              _this.opt.form.forEach((item) => {
                if (item.key == "buildingNo") {
                  item.disabled = true;
                }
              });
            }
            res.shortDiscountMonthlyRangeList = [];
            res.imgs = [];
            if (res.rentalStyle == 2) {
              res.shortPayStyle = "1";
            }
            if (res.roomPicList) {
              let roomPicList = [];
              res.roomPicList.forEach((item, index) => {
                roomPicList.push({
                  name: `${index}.jpg`,
                  url: item,
                  src: item,
                });
              });
              res.roomPicList = roomPicList;
            }
            if (
              res.apartmentReq == undefined ||
              res.apartmentReq == null ||
              res.apartmentReq.name == null
            ) {
              res.apartmentId = null;
            }
            res.rentalDiscountType = "0";
            res.shortDiscountType = "0";
            if (res.rentalDiscountFlag == 2 || res.rentalDiscountFlag == 3) {
              res.rentalDiscountType = "1";
              res.rentalDiscountFlag = "1";
              res.rentalDiscountTimeRange = [
                moment(res.rentalDiscountStartDate),
                moment(res.rentalDiscountEndDate),
              ];
            }
            if (!res.rentalDiscountFlag) {
              res.rentalDiscountFlag = "0";
            }
            if (!res.shortDiscountFlag) {
              res.shortDiscountFlag = "0";
            }
            if ([1, 4, 5, 6].indexOf(Number(res.shortDiscountFlag)) != -1) {
              res.shortDiscountType = {
                1: "0",
                4: "1",
                6: "2",
                5: "3",
              }[res.shortDiscountFlag];
              res.shortDiscountFlag = "1";
              let _shortDiscountDetail = {
                duration: [],
                weekly: [],
                monthly: [],
              };
              if (res.shortDiscountDetail) {
                _shortDiscountDetail = JSON.parse(res.shortDiscountDetail);
              }
              switch (res.shortDiscountType) {
                case "1":
                  let _shortDiscountDateRangeList = [];
                  for (
                    let i = 0;
                    i < _shortDiscountDetail.duration.length;
                    i++
                  ) {
                    let item = _shortDiscountDetail.duration[i];
                    _shortDiscountDateRangeList.push([item[0], item[1]]);
                  }
                  res.shortDiscountDateRangeList = _shortDiscountDateRangeList;
                  break;
                case "2":
                  let _shortDiscountWeeklyRangeList = [];
                  for (let i = 0; i < _shortDiscountDetail.weekly.length; i++) {
                    let item = _shortDiscountDetail.weekly[i];
                    _shortDiscountWeeklyRangeList.push(Number(item) - 1);
                  }
                  res.shortDiscountWeeklyRangeList =
                    _shortDiscountWeeklyRangeList;
                  break;
                case "3":
                  res.shortDiscountMonthlyRangeList =
                    _shortDiscountDetail.monthly;
                  break;
                default:
                  break;
              }
            }
            if (res.rentalDiscountFlag != 0) {
              res.rentalFee = res.rentalOriginalFee;
            }
            if (res.shortDiscountFlag != 0) {
              res.shortRentFee = res.shortOriginalFee;
            }
            _this.chargingModel = res.chargingModel;
            // _this.cache.setLS("chargingModel", res.chargingModel);
            // _this.res.chargingModel=res.chargingModel;
            if (
              res.chargingModel &&
              JSON.stringify(res.chargingModel) != "{}"
            ) {
              if (res.chargingModel["1"]) {
                res.electricityFees = res.chargingModel["1"].model.toString();
                res.electricityFeesId = res.chargingModel["1"].id;
                res.electricityFeesPrice = res.chargingModel["1"].unitPrice
                  ? parseFloat(res.chargingModel["1"].unitPrice).toFixed(2)
                  : res.chargingModel["1"].unitPrice;
              } else {
                res.electricityFees = "4";
              }
              if (res.chargingModel["2"]) {
                res.coldWater = res.chargingModel["2"].model.toString();
                res.coldWaterId = res.chargingModel["2"].id;
                if (res.coldWater == 2) {
                  res.coldWaterPrice = res.chargingModel["2"].unitPrice
                    ? parseFloat(res.chargingModel["2"].unitPrice).toFixed(2)
                    : res.chargingModel["2"].unitPrice;
                } else {
                  res.coldWaterPrice2 = res.chargingModel["2"].unitPrice
                    ? parseFloat(res.chargingModel["2"].unitPrice).toFixed(2)
                    : res.chargingModel["2"].unitPrice;
                }
              } else {
                res.coldWater = "4";
              }
              if (res.chargingModel["3"]) {
                res.hotWater = res.chargingModel["3"].model.toString();
                res.hotWaterId = res.chargingModel["3"].id;

                if (res.hotWater == 2) {
                  res.hotWaterPrice = res.chargingModel["3"].unitPrice
                    ? parseFloat(res.chargingModel["3"].unitPrice).toFixed(2)
                    : res.chargingModel["3"].unitPrice;
                } else {
                  res.hotWaterPrice2 = res.chargingModel["3"].unitPrice
                    ? parseFloat(res.chargingModel["3"].unitPrice).toFixed(2)
                    : res.chargingModel["3"].unitPrice;
                }
              } else {
                res.hotWater = "4";
              }
            } else {
              res.electricityFees = "4";
              res.coldWater = "4";
              res.hotWater = "4";
            }
            //  res.canEdit=0;
            if (res.canEdit == 0 && _this.$route.params.type == "edit") {
              _this.opt.form.forEach((item) => {
                if (
                  item.key == "apartmentId" ||
                  item.key == "roomType" ||
                  item.key == "buildingNo" ||
                  item.key == "unitNo" ||
                  item.key == "floor" ||
                  item.key == "roomNo"
                ) {
                  item.disabled = true;
                }
              });
              if (res.rentalStyle == "1") {
                _this.opt.form[1].opt.list = [
                  {
                    label: "短租",
                    value: "1",
                  },
                  {
                    label: "长短租",
                    value: "2",
                  },
                ];
              } else if (res.rentalStyle == "0") {
                _this.opt.form[1].opt.list = [
                  {
                    label: "长租",
                    value: "0",
                  },
                  {
                    label: "长短租",
                    value: "2",
                  },
                ];
              } else if (res.rentalStyle == "2") {
                _this.opt.form[1].opt.list = [
                  {
                    label: "长短租",
                    value: "2",
                  },
                ];
              } else if (res.rentalStyle == "3") {
                _this.opt.form[1].opt.list = [
                  {
                    label: "钟点房",
                    value: "3",
                  },
                ];
              }
            }
            res.isShow = res.isShow.toString();
            opt.cb(res);
          });
        },
        apartmentList: [],
        buildingsList: [],
      },
      chargingModel: {},
      config: {
        apartmentIdShow: true,
        rentalStyleShow: true,
        roomTypeShow: true,
        buildingNoShow: true,
        floorShow: true,
        roomNoShow: true,
        roomPicListShow: true,
        descriptionShow: true,
        isShowShow: true,

        contractTypeShow: true,
        payDateShow: true,
        expiredDayShow: true,
        expiredTimeShow: true,
        rentalFeeShow: true,
        depositShow: true,
        propertyFeeShow: true,
        electricityFeesShow: true,
        coldWaterShow: true,
        hotWaterShow: true,

        apartmentIdRequired: true,
        rentalStyleRequired: true,
        roomTypeRequired: true,
        buildingNoRequired: true,
        floorRequired: true,
        roomNoRequired: true,
        roomPicListRequired: false,
        descriptionRequired: false,
        isShowRequired: false,

        contractTypeRequired: false,
        payDateRequired: false,
        expiredDayRequired: false,
        expiredTimeRequired: false,
        rentalFeeRequired: false,
        depositRequired: false,
        propertyFeeRequired: false,
        electricityFeesRequired: false,
        coldWaterRequired: false,
        hotWaterRequired: false,
      },
    };
  },
  created: function () {
    console.log("room-detail created!!");
    this.opt.form.forEach((item) => {
      if (
        item.key == "apartmentId" ||
        item.key == "roomType" ||
        item.key == "buildingNo" ||
        item.key == "unitNo" ||
        item.key == "floor" ||
        item.key == "roomNo"
      ) {
        item.disabled = false;
      }
    });
  },
  activated() {
    this.getApartmentList();
    this.$refs.detail.title = ["添加房间", "编辑房间", "房间详情"][
      {
        add: 0,
        edit: 1,
        show: 2,
      }[this.$route.params.type]
    ];
    this.opt.form[1].opt.list = [
      {
        label: "长租",
        value: "0",
      },
      {
        label: "短租",
        value: "1",
      },
      {
        label: "长短租",
        value: "2",
      },
      {
        label: "钟点房",
        value: "3",
      },
    ];
    this.opt.form.forEach((item) => {
      if (
        item.key == "apartmentId" ||
        item.key == "roomType" ||
        item.key == "buildingNo" ||
        item.key == "unitNo" ||
        item.key == "floor" ||
        item.key == "roomNo"
      ) {
        item.disabled = false;
      }
    });
    if (this.$route.params.type == "add") {
      if (this.getSchoolLandlordFlag()) {
        this.setSchoolHouseConfig();
      } else {
        this.setOrdinaryHouseConfig();
      }
    } else if (
      this.$route.params.type == "edit" ||
      this.$route.params.type == "show"
    ) {
      if (this.$route.query.apartmentType == 7) {
        this.setSchoolHouseConfig();
      } else {
        this.setOrdinaryHouseConfig();
      }
    }
  },
  methods: {
    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"],
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        (res) => {
          this.opt.form.forEach((data) => {
            if (data.key === "apartmentId") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              this.apartmentList = res;
            }
          });
        }
      );
    },
    // 获取房型
    getTypeIdList(apartmentId) {
      this.post("landlord-service/base/getRoomTypeAll", {}).then((res) => {
        let baseType = {};
        res.forEach((item) => {
          baseType[item.id] = item.name;
        });
        this.post("landlord-service/roomType/queryPagesRoomType", {
          pageSize: "99999",
          pageNumber: "1",
          landlordId:
            this.cache.getLS("userInfo")["roleType"] == "1"
              ? null
              : this.cache.getLS("userInfo")["userId"],
          apartmentId: apartmentId,
        }).then((resData) => {
          this.opt.form.forEach((data) => {
            if (data.key === "roomType") {
              resData.forEach((item) => {
                item.label = baseType[item.typeId];
                item.value = item.typeId;
              });
              data.opt.list = resData;
            }
          });
        });
      });
    },
    // 设置学校房源
    setSchoolHouseConfig() {
      this.getHouseConfig().then((res) => {
        let roomConfig = [];
        let roomRentalConfig = [];
        roomConfig = res.roomConfig;
        roomRentalConfig = res.roomRentalConfig;
        console.log("roomConfig:", roomConfig);
        console.log("roomRentalConfig:", roomRentalConfig);
        roomConfig.map((item) => {
          if (item.property == "house") {
            this.config.apartmentIdShow = item.display;
            this.config.apartmentIdRequired = item.fill;
          }
          if (item.property == "rental_style") {
            this.config.rentalStyleShow = item.display;
            this.config.rentalStyleRequired = item.fill;
          }
          if (item.property == "room_type") {
            this.config.roomTypeShow = item.display;
            this.config.roomTypeRequired = item.fill;
          }
          if (item.property == "building_name") {
            this.config.buildingNoShow = item.display;
            this.config.buildingNoRequired = item.fill;
          }
          if (item.property == "floor") {
            this.config.floorShow = item.display;
            this.config.floorRequired = item.fill;
          }
          if (item.property == "room_no") {
            this.config.roomNoShow = item.display;
            this.config.roomNoRequired = item.fill;
          }
          if (item.property == "room_pic") {
            this.config.roomPicListShow = item.display;
            this.config.roomPicListRequired = item.fill;
          }
          if (item.property == "room_desc") {
            this.config.descriptionShow = item.display;
            this.config.descriptionRequired = item.fill;
          }
          if (item.property == "publish_to") {
            this.config.isShowShow = item.display;
            this.config.isShowRequired = item.fill;
          }
        });
        roomRentalConfig.map((item) => {
          if (item.property == "rental_contract_type") {
            this.config.contractTypeShow = item.display;
            this.config.contractTypeRequired = item.fill;
          }
          if (item.property == "pay_date") {
            this.config.payDateShow = item.display;
            this.config.payDateRequired = item.fill;
          }
          if (item.property == "expired_day") {
            this.config.expiredDayShow = item.display;
            this.config.expiredDayRequired = item.fill;
          }
          if (item.property == "expired_time") {
            this.config.expiredTimeShow = item.display;
            this.config.expiredTimeRequired = item.fill;
          }
          if (item.property == "rental_fee") {
            this.config.rentalFeeShow = item.display;
            this.config.rentalFeeRequired = item.fill;
          }
          if (item.property == "deposit") {
            this.config.depositShow = item.display;
            this.config.depositRequired = item.fill;
          }
          if (item.property == "property_fee") {
            this.config.propertyFeeShow = item.display;
            this.config.propertyFeeRequired = item.fill;
          }
          if (item.property == "electric_fee") {
            this.config.electricityFeesShow = item.display;
            this.config.electricityFeesRequired = item.fill;
          }
          if (item.property == "cold_water_fee") {
            this.config.coldWaterShow = item.display;
            this.config.coldWaterRequired = item.fill;
          }
          if (item.property == "hot_water_fee") {
            this.config.hotWaterShow = item.display;
            this.config.hotWaterRequired = item.fill;
          }
        });
        this.opt.form.forEach((item, index) => {
          if (item.key == "rentalStyle") {
            item.opt.list = [
              {
                label: "长租",
                value: "0",
              },
            ];
          }
          if (item.key == "apartmentId") {
            item.rules[0].required = this.config.apartmentIdRequired;
            item.notShow = !this.config.apartmentIdShow;
          }
          if (item.key == "rentalStyle") {
            item.rules[0].required = this.config.rentalStyleRequired;
            item.notShow = !this.config.rentalStyleShow;
          }
          if (item.key == "roomType") {
            item.rules[0].required = this.config.roomTypeRequired;
            item.notShow = !this.config.roomTypeShow;
          }
          if (item.key == "buildingNo") {
            item.rules[0].required = this.config.buildingNoRequired;
            item.notShow = !this.config.buildingNoShow;
          }

          if (item.key == "floor") {
            item.rules[0].required = this.config.floorRequired;
            item.notShow = !this.config.floorShow;
          }
          if (item.key == "roomNo") {
            item.rules[0].required = this.config.roomNoRequired;
            item.notShow = !this.config.roomNoShow;
          }
          if (item.key == "roomPicList") {
            item.notShow = !this.config.roomPicListShow;
          }
          if (item.key == "description") {
            item.notShow = !this.config.descriptionShow;
          }
          if (item.key == "isShow") {
            item.rules[0].required = this.config.isShowRequired;
            item.notShow = !this.config.isShowShow;
          }
          if (item.key == "contractType") {
            item.rules[0].required = this.config.contractTypeRequired;
            item.notShow = !this.config.contractTypeShow;
          }
          if (item.key == "payDate") {
            item.rules[0].required = this.config.payDateRequired;
            item.notShow = !this.config.payDateShow;
          }
          if (item.key == "expiredDay") {
            item.rules[0].required = this.config.expiredDayRequired;
            item.notShow = !this.config.expiredDayShow;
          }
          if (item.key == "expiredTime") {
            item.rules[0].required = this.config.expiredTimeRequired;
            item.notShow = !this.config.expiredTimeShow;
          }
          if (item.key == "rentalFee") {
            item.rules[0].required = this.config.rentalFeeRequired;
            item.notShow = !this.config.rentalFeeShow;
          }
          if (item.key == "deposit") {
            item.notShow = !this.config.depositShow;
          }
          if (item.key == "propertyFee") {
            item.notShow = !this.config.propertyFeeShow;
          }
          if (item.key == "electricityFees") {
            item.rules[0].required = this.config.electricityFeesRequired;
            item.notShow = !this.config.electricityFeesShow;
          }
          if (item.key == "coldWater") {
            item.rules[0].required = this.config.coldWaterRequired;
            item.notShow = !this.config.coldWaterShow;
          }
          if (item.key == "hotWater") {
            item.rules[0].required = this.config.hotWaterRequired;
            item.notShow = !this.config.hotWaterShow;
          }
        });
      });
    },

    // 设置普通房源
    setOrdinaryHouseConfig() {
      this.opt.form.forEach((item, index) => {
        if (item.key == "rentalStyle") {
          item.opt.list = [
            {
              label: "长租",
              value: "0",
            },
            {
              label: "短租",
              value: "1",
            },
            {
              label: "长短租",
              value: "2",
            },
            {
              label: "钟点房",
              value: "3",
            },
          ];
        }
        if (item.key == "apartmentId") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "rentalStyle") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "roomType") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "buildingNo") {
          item.rules[0].required = true;
          item.notShow = false;
        }

        if (item.key == "floor") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "roomNo") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "roomPicList") {
          item.notShow = false;
        }
        if (item.key == "description") {
          item.notShow = false;
        }
        if (item.key == "isShow") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "contractType") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "payDate") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "expiredDay") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "expiredTime") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "rentalFee") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "deposit") {
          item.notShow = false;
        }
        if (item.key == "propertyFee") {
          item.notShow = false;
        }
        if (item.key == "electricityFees") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "coldWater") {
          item.rules[0].required = true;
          item.notShow = false;
        }
        if (item.key == "hotWater") {
          item.rules[0].required = true;
          item.notShow = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.room-detail {
  .img-list {
    white-space: nowrap;
    overflow-x: auto;

    img {
      margin-right: 10px;
      height: 146px;
      width: 146px;
      cursor: pointer;
    }
  }
}
</style>